.invoices .purchase-owner {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.ui.basic.striped.table>tbody>tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.ui.ui.selectable.table>tbody>tr:hover,
.ui.table tbody tr td.selectable:hover {
  background: rgba(0, 0, 0, 0.06) !important;
}

.highlight-warning {
  font-weight: bold;
  color: #ed1c24;
}

.highlight-positive {
  font-weight: bold;
  color: green;
}

.page>.content {
  display: flex;
  flex-direction: row;
  padding: 50px 30px;
  height: 100%;
  overflow: hidden;
}

.add-row, .remove-row {
  label {
    opacity: 0;
  }
}

.side-navigation.ui.menu {
  display: flex !important;
  flex-direction: column;
  position: fixed;
  border-radius: 0 !important;
  overflow: visible;
  white-space: nowrap;
  z-index: 1010;
  height: 100%;
}

.page .side-navigation .spacer {
  flex-grow: 1;
  padding: 0.8rem;
}

.page .side-navigation .avatar,
.page .invoice-detail .header .label,
.page .user-detail .header .label {
  float: right;
}

.page .top-navigation {
  margin-bottom: 40px !important;
}

.page .side-navigation .icon-bar {
  padding: 10px 16px;
}

.page .side-navigation .icon-bar .icon {
  margin-right: 20px;
}

.page .side-navigation .icon-bar .icon:hover {
  color: #db2828;
  cursor: pointer;
}

.page .side-navigation .icon.action {
  margin-right: 20px;
}

.page .side-navigation .icon.action:hover {
  color: #db2828;
  cursor: pointer;
}

.page>.content .role-assignments .controls {
  padding-top: 45px;
}

.page .content .role-assignments button {
  margin-bottom: 16px;
}

.page .content .role-assignments .segment {
  padding: 8px 16px !important;
}

@media (min-width: 600px) {
  .login-container {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    min-width: 810px;
  }
}

.ui.container .ui.grid {
  margin-bottom: 6em;
}

.page .top-navigation .item:hover:not(.active) {
  border-bottom-color: black !important;
}

.page .side-navigation a:hover {
  background: rgba(0, 0, 0, 0.02);
}

.page .dashboard-widget-modal .component-icon {
  width: 55px !important;
}

.page .dashboard-widget>.header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0.5em 1em !important;
  border-color: #db2828 !important;
}

.page .dashboard-widget .vm {
  display: flex !important;
  align-items: center;
}

.ui.label.test-flag {
  width: 85%;
  text-align: center;
  margin: 10px auto !important;
  margin-top: 5px !important;
}

.ui.label.test-flag-collapsed {
  width: 85%;
  text-align: center;
  margin: 0 auto !important;
  margin-top: 5px !important;
}

.page .disabled {
  color: lightgrey;
}

.clickable {
  cursor: pointer;
}

.text-link {
  background: white;
  opacity: 0.8;
}

.angle {
  box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-image: initial;
  border-color: rgba(34, 36, 38, 0.15);
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30%;
  background-color: white;
  min-height: 25px;
  padding-top: 5px;
}

.angle-container {
  padding-left: 14.9em;
  padding-top: 8em;
  position: absolute;
}

.angle-container-collapsed {
  padding-left: 2.9em;
  padding-top: 8em;
  position: relative;
}

.ui.vertical.menu {
  margin-top: 0 !important;
}

.ui.vertical.menu .active.item {
  background: rgba(237, 28, 36, 0.2) !important;
}

.menu-burger {
  display: none;
}

.menu-burgerClose {
  display: none;
}

.flex {
  display: flex !important;
  align-items: center;
  padding: 3px !important;
}

.icons-flex {
  justify-content: space-between;
}

.transaction {
  padding: 0 !important;
}

.topTable-flex {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.table-top {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.detail-flex {
  display: flex;
  padding-top: 1rem !important;
}

.transaction-plan-detail {
  padding-bottom: 2rem;
}

.plan-detail {
  margin-bottom: 2rem !important;
}

.action-btn {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.status {
  margin-left: 1rem;
}

.mobile-btn {
  display: none !important;
}

.ui.dropdown .menu .dropdown-menu {
  position: absolute !important;
  left: -185px !important;
  top: -12px !important;
}

.ui.dropdown .menu.dropdown-top {
  position: absolute !important;
  left: -175px !important;
  top: 20px !important;
}

.flex {
  display: flex !important;
  align-items: center;
  padding: 3px !important;
}

.icons-flex {
  justify-content: space-between;
}

.transaction {
  padding: 0 !important;
}

.topTable-flex {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.table-top {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.detail-flex {
  display: flex;
  padding-top: 1rem !important;
}

.transaction-plan-detail {
  padding-bottom: 2rem;
}

.plan-detail {
  margin-bottom: 2rem !important;
}

.action-btn {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.status {
  margin-left: 1rem;
}

.mobile-btn {
  display: none !important;
}

.menu-wrapp {
  display: flex !important;
  flex-direction: column !important;
  padding-top: 3.8rem !important;
}

.flex-transaction {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.table-detailTransaction {
  min-width: 300px;
}

.wrapp-table-detailTransaction {
  padding-left: 5rem;
}

@media (max-width: 1100px) {
  .wrapp-table-detailTransaction {
    padding-left: 0rem;
  }

  .ui.vertical.menu {
    flex-direction: unset !important;
    width: 100% !important;
    height: 3rem;
    padding: 0.8rem;
  }

  .menu-burger {
    display: block;
  }

  .menu-wrapp {
    display: none;
  }

  .menu-wrapp.active {
    padding-top: 42px !important;
    background-color: white;
    height: calc(100vh + 42px);
    position: absolute;
    top: 0;
    left: -1px;
    bottom: 0;
    z-index: 10;

    align-items: inherit;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
  }

  .menu-wrappTop {
    display: none;
  }

  .menu-burgerClose {
    display: block;
    padding: 1rem;
    text-align: end;
  }

  .page>.content {
    padding: 50px 30px;
  }

  .page {
    position: relative;
  }

  .scroll {
    overflow: hidden;
    height: 100vh;
  }

  .menu-wrapp.active>.page {
    background: red;
  }

  .angle-container {
    display: none;
  }

  .shadow-box {
    position: absolute;
    top: -42px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.48);
    z-index: 10;
    height: calc(100vh + 42px);
  }

  .ui.container {
    width: 100% !important;
  }

  .page > .content {
    overflow: hidden;
    margin-left: 0 !important;
    padding-top: 5rem !important;
  }

  .month-wrap {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
    padding-top: 0.7rem;
  }

  .month-btn {
    width: 100% !important;
    border-radius: 0 !important;
  }

  .top-navigation {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .detail-flex {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .flex-transaction {
    flex-direction: column !important;
  }

  .popup-download {
    display: flex;
    flex-direction: unset !important;
    align-items: center;
    text-align: center !important;
  }

  .ui.dropdown .menu.dropdown-menu {
    left: 8px !important;
  }

  .ui.simple.dropdown .menu.dropdown-menu .item:hover>.menu {
    top: -297px !important;
  }
}

@media (max-width: 770px) {
  .flex-expenses {
    display: flex !important;
    align-items: center !important;
  }

  .flex-column{
    display: flex;
    justify-content: space-between;
  }

  .overview-flex {
    display: flex !important;
    flex-direction: column !important;
  }

  .overview-file {
    max-width: 300px;
  }

  .new-btn {
    box-shadow: none !important;
  }

  .mobile-align {
    text-align: left !important;
  }

  .overview-img {
    margin-bottom: 1.5rem !important;
  }

  .tr-add {
    box-shadow: none !important;
  }

  .tr-add button {
    margin-bottom: 1rem !important;
  }

  .overview-img,
  .overview-info,
  .table-fullSize {
    width: 100% !important;
  }
}

@media (max-width: 570px) {
  .flex-login {
    display: flex !important;
    flex-direction: column !important;
  }

  .login-template {
    width: 100% !important;
    min-width: 351px !important;
  }

  .topTable-flex {
    flex-direction: column;
    align-items: center !important;
  }

  .detail-invoice {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }

  .status {
    margin-left: 0 !important;
  }

  .action-btnSpan {
    display: none;
  }

  .action-btn i {
    margin: 0 !important;
  }

  .title {
    font-size: 20px;
  }
}

@media (max-width: 440px) {
  .month-wrap {
    grid-template-columns: repeat(2, 1fr) !important;
    padding-top: 0.5rem;
  }

  .popup-download {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-btn {
    display: block !important;
  }

  .mobile-btnHidden {
    display: none !important;
  }
}

.breadcrumbs {
  padding-bottom: 0.8rem;
}

.breadcrumbs-item {
  font-size: 18px;
}

.breadcrumbs-itemLink a {
  font-size: 18px;
  color: #db2828 !important;
  text-decoration: underline;
  font-weight: 600;
}

.detail-invoice {
  padding-left: 0 !important;
}

.invoice-subject-detail {
  padding-top: 0.5rem;
}

.overview-grid {
  margin-bottom: 0 !important;
}

.flex-center {
  display: flex;
  align-items: baseline;
}

.input-center {
  margin-bottom: 0 !important;
  padding-right: 0 !important;
}

.span-from {
  display: block;
  margin: 0 10px !important;
}

.button-ppv {
  margin-bottom: 3rem !important;
}

.ui.bottom.attached.segment.active.tab {
  border: none;
}

.formUpload ul.ui.list, ol.ui.list, .ui.list {
  margin: 1.6em 0.5em !important;
}
