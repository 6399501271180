body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.summary-detail {
  margin: 15px 0 !important;
}

.ui.red.buttons .active.button {
  background-color: #8e0606 !important;
}

.buttongroup--months {
  padding-left: 30px;
}
